<template>
  <div>
    <!-- 客服弹框 -->
    <div v-if="isShowCustomerServiceWindow" class="tccc">
      <div class="title">
        <span>在线客服</span>
        <div class="close-icon" @click="handleCloseWindow">X</div>
      </div>
      <div style="background: #fff">
        <iframe class="iframe-wrap" :src="iframeSrc" frameborder="0"></iframe>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
const iframeSrc = ref(
  "https://tccc.qcloud.com/web/im/index.html#/chat?webAppId=bc933d4e3223963f48574f92adcb0fe7&title=报价&sideBarName=在线客服"
);
const emits = defineEmits(["handleCloseCustomerService"]);
const props = defineProps({
  isShowCustomerServiceWindow: {
    type: Boolean,
    default: false,
  },
});

watch(props, (newValue, oldValue) => {
  if (newValue.isShowCustomerServiceWindow) {
    handleOpen();
  } else {
    handleClose();
  }
});

function handleOpen() {
  if (!iframeSrc.value) {
    iframeSrc.value =
      "https://tccc.qcloud.com/web/im/index.html#/chat?webAppId=bc933d4e3223963f48574f92adcb0fe7&title=报价&sideBarName=在线客服";
  }
}
// handleOpen();
function handleCloseWindow() {
  emits("handleCloseCustomerService", false);
  // iframeSrc.value = "";
}
function handleClose() {
  iframeSrc.value = "";
}
</script>

<style lang="scss" scoped>
.tccc {
  position: fixed;
  right: 0;
  bottom: 5px;
  z-index: 9999;
  width: 300px;
  height: 555px;
  background: #e6e7e8;
  .title {
    position: relative;
    height: 55px;
    line-height: 55px;
    text-align: center;
    background: #333537;
    color: #fff;
    font-size: 16px;
    .close-icon {
      position: absolute;
      right: 0;
      top: 0;
      width: 55px;
      height: 55px;
      cursor: pointer;
    }
  }
  .iframe-wrap {
    width: 300px;
    height: 500px;
    border-radius: 20px;
  }
}
</style>
